import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  CardMedia,
  IconButton,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  CircularProgress,
  Link,
  DialogContentText,
} from "@mui/material";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import HomeIcon from "@mui/icons-material/Home";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SearchIcon from "@mui/icons-material/Search";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import {
  AccountCircle as AccountCircleIcon,
  Event as EventIcon,
  AttachMoney as AttachMoneyIcon,
  AddCircle as AddCircleIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CopyToClipboardComponent from "./copy";
import { isLoggedIn } from "../session";
import { auth, db, saveTokenToFirestore, messaging } from "../firebase";
import {
  addDoc,
  collection,
  onSnapshot,
  doc,
  query,
  orderBy,
  serverTimestamp,
  getDocs,
  deleteField,
  where,
  setDoc,
  updateDoc,
  increment,
  getDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import logoSvg from "../imgs/logo.svg";
import NotificationIcon, { subscribeToNotifications } from "./NotificationIcon";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useWatchPosition } from "react-use-geolocation";
import { getToken } from "firebase/messaging";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [userLikes, setUserLikes] = useState({});

  

  useEffect(() => {
    // Verifica que el usuario esté autenticado
    if (auth.currentUser) {
      requestNotificationPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser]);

  async function requestNotificationPermission() {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey:
            "BN-njmyixSEuzLm64hdgPik9iz4vDxG7XxtsRJD6WHNply94JZpXtmkaHOeGFwUMqDZMOPEnrt-eJTBBZZkIbbQ",
        });
        console.log("FCM token:", token);

        if (token) {
          // Envía el token a tu backend para almacenarlo en Firestore
          await saveTokenToFirestore(token);
          // Manejar mensajes en primer plano
        } else {
          console.error("No se pudo obtener el token FCM");
        }
      } else {
        console.error("Permiso de notificaciones denegado");
      }
    } catch (error) {
      console.error("Error al obtener el token FCM:", error);
    }
  }

  // Suscripción a notificaciones
  useEffect(() => {
    const unsubscribe = subscribeToNotifications(setHasNewNotifications);
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const listenForNewPostsAndEvents = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user is currently authenticated");
        return;
      }

      try {
        // Consulta las empresas seguidas por el usuario
        const followedCompaniesQuery = query(
          collection(db, "followedCompanies"),
          where("userId", "==", user.uid)
        );

        const followedCompaniesSnapshot = await getDocs(followedCompaniesQuery);
        const followedCompanies = followedCompaniesSnapshot.docs.map(
          (doc) => doc.id
        );

        if (followedCompanies.length === 0) {
          console.log("User is not following any companies");
          return;
        }

        console.log("Followed companies: ", followedCompanies);

        // Escuchar nuevos posts en "offers"
        const offersQuery = collection(db, "offers");
        const unsubscribeOffers = onSnapshot(offersQuery, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              const newPost = change.doc.data();
              console.log("New offer: ", newPost);
              const postUserId = newPost.userId || newPost.uid || newPost.uide; // Asegurando el campo correcto
              if (!postUserId) {
                console.error("Offer does not have a valid userId", newPost);
                return;
              }

              if (followedCompanies.includes(postUserId)) {
                // Consultar si ya existe una notificación para este post
                const notificationQuery = query(
                  collection(db, "notifications"),
                  where("userId", "==", user.uid),
                  where("postId", "==", change.doc.id),
                  where("type", "==", "offer")
                );

                getDocs(notificationQuery)
                  .then((querySnapshot) => {
                    if (querySnapshot.empty) {
                      // Añadir nueva notificación de oferta si no existe
                      addDoc(collection(db, "notifications"), {
                        userId: user.uid,
                        companyId: postUserId,
                        postId: change.doc.id,
                        message: `${
                          newPost.displayName || newPost.name
                        } ha publicado una nueva oferta`,
                        type: "offer",
                        read: false,
                        timestamp: serverTimestamp(),
                      })
                        .then(() => {
                          console.log("Offer notification added successfully");
                        })
                        .catch((error) => {
                          console.error(
                            "Error adding offer notification: ",
                            error
                          );
                        });
                    } else {
                      console.log(
                        "Offer notification already exists for this post"
                      );
                    }
                  })
                  .catch((error) => {
                    console.error(
                      "Error querying offer notifications: ",
                      error
                    );
                  });
              }
            }
          });
        });

        // Escuchar nuevos eventos en "events"
        const eventsQuery = collection(db, "events");
        const unsubscribeEvents = onSnapshot(eventsQuery, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              const newEvent = change.doc.data();
              console.log("New event: ", newEvent);
              const eventUserId =
                newEvent.userId || newEvent.uid || newEvent.uide; // Asegurando el campo correcto
              if (!eventUserId) {
                console.error("Event does not have a valid userId", newEvent);
                return;
              }

              if (followedCompanies.includes(eventUserId)) {
                // Consultar si ya existe una notificación para este evento
                const notificationQuery = query(
                  collection(db, "notifications"),
                  where("userId", "==", user.uid),
                  where("postId", "==", change.doc.id),
                  where("type", "==", "event")
                );

                getDocs(notificationQuery)
                  .then((querySnapshot) => {
                    if (querySnapshot.empty) {
                      // Añadir nueva notificación de evento si no existe
                      addDoc(collection(db, "notifications"), {
                        userId: user.uid,
                        companyId: eventUserId,
                        postId: change.doc.id,
                        message: `${
                          newEvent.displayName || newEvent.name
                        } ha publicado un nuevo evento`,
                        type: "event",
                        read: false,
                        timestamp: serverTimestamp(),
                      })
                        .then(() => {
                          console.log("Event notification added successfully");
                        })
                        .catch((error) => {
                          console.error(
                            "Error adding event notification: ",
                            error
                          );
                        });
                    } else {
                      console.log(
                        "Event notification already exists for this post"
                      );
                    }
                  })
                  .catch((error) => {
                    console.error(
                      "Error querying event notifications: ",
                      error
                    );
                  });
              }
            }
          });
        });

        return () => {
          unsubscribeOffers();
          unsubscribeEvents();
        };
      } catch (error) {
        console.error("Error listening for new posts and events: ", error);
      }
    };

    listenForNewPostsAndEvents();
  }, []);

  useEffect(() => {
    const checkAuthStatus = async () => {
      const loggedIn = await isLoggedIn();
      setUserAuthenticated(loggedIn);
      setLoading(false);
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    const fetchUserLikes = async () => {
      if (userAuthenticated && auth.currentUser) {
        const userLikesRef = doc(db, "userLikes", auth.currentUser.uid);
        const userLikesDoc = await getDoc(userLikesRef);
        if (userLikesDoc.exists()) {
          setUserLikes(userLikesDoc.data());
        }
      }
    };

    if (userAuthenticated) {
      fetchUserLikes();
    }
  }, [userAuthenticated]);

  const handleMeSirve = async (offerId) => {
    if (!userAuthenticated) {
      console.log("Debes iniciar sesión para usar esta función");
      // Aquí podrías redirigir al usuario a la página de login si lo deseas
      return;
    }

    const userId = auth.currentUser.uid;
    const offerRef = doc(db, "offers", offerId);
    const userLikesRef = doc(db, "userLikes", userId);

    try {
      const offerDoc = await getDoc(offerRef);
      const offerData = offerDoc.data();
      const currentLikes = offerData.likes || [];

      if (currentLikes.includes(userId)) {
        await updateDoc(offerRef, {
          likes: arrayRemove(userId),
          helpedCount: increment(-1),
        });
        await updateDoc(userLikesRef, {
          [offerId]: deleteField(),
        });
        console.log("'Me sirve' removido");
      } else {
        await updateDoc(offerRef, {
          likes: arrayUnion(userId),
          helpedCount: increment(1),
        });
        await setDoc(userLikesRef, { [offerId]: true }, { merge: true });
        console.log("'Me sirve' agregado");
      }

      setOffers(
        offers.map((offer) =>
          offer.id === offerId
            ? {
                ...offer,
                helpedCount:
                  offer.helpedCount + (currentLikes.includes(userId) ? -1 : 1),
              }
            : offer
        )
      );
      setUserLikes((prevLikes) => ({
        ...prevLikes,
        [offerId]: !prevLikes[offerId],
      }));
    } catch (error) {
      console.error("Error al actualizar 'Me sirve':", error);
    }
  };

  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const fetchOffersAndStats = async () => {
      try {
        setLoading(true);
        const offersCollectionRef = collection(db, "offers");

        const unsubscribeOffers = onSnapshot(
          query(offersCollectionRef, orderBy("createdAt", "desc")),
          (snapshot) => {
            const offersData = snapshot.docs.map((doc) => {
              const data = doc.data();
              let imageUrls = [];
              if (data.images && Array.isArray(data.images)) {
                imageUrls = data.images;
              } else if (data.images && typeof data.images === "string") {
                imageUrls = [data.images];
              }

              return {
                id: doc.id,
                ...data,
                imageUrls,
                helpedCount: data.helpedCount || 0, // Añadir contador individual
              };
            });
            setLoading(false);
            setOffers(offersData);
            console.log("Ofertas obtenidas:", offersData);
          }
        );

        return () => {
          unsubscribeOffers();
        };
      } catch (error) {
        console.error("Error al obtener ofertas o estadísticas:", error);
        setLoading(false);
      }
    };

    fetchOffersAndStats();
  }, []);

  const handleClickPerfil = (offer) => {
    // Add offer as parameter
    if (offer) {
      // Check if offer is available
      navigate(`/perfil/${offer.uid}`);
    }
  };

  const [following, setFollowing] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFollowing = async () => {
      try {
        const isAuthenticated = await isLoggedIn();
        if (isAuthenticated) {
          const { uid } = auth.currentUser || {};
          if (uid) {
            const userFollowingRef = doc(db, "userFollowing", uid);
            const userFollowingDoc = await getDoc(userFollowingRef);

            if (userFollowingDoc.exists()) {
              const followingData = userFollowingDoc.data();
              const followingIds = Object.keys(followingData);
              setFollowing(followingIds);
            } else {
              setFollowing([]);
            }
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener las empresas seguidas:", error);
        setLoading(false);
      }
    };

    fetchFollowing();
  }, []);

  const handleFollow = async (companyId) => {
    try {
      const isAuthenticated = await isLoggedIn();
      if (isAuthenticated) {
        const { uid } = auth.currentUser || {};
        if (uid) {
          setLoading(true);
          const userFollowingRef = doc(db, "userFollowing", uid);
          const companyFollowersRef = doc(db, "companyFollowers", companyId);

          const isFollowing = following.includes(companyId);

          if (isFollowing) {
            // Dejar de seguir
            await updateDoc(userFollowingRef, {
              [companyId]: deleteField(),
            });
            await updateDoc(companyFollowersRef, {
              [uid]: deleteField(),
            });
            setFollowing(following.filter((id) => id !== companyId));
          } else {
            // Comenzar a seguir
            await setDoc(
              userFollowingRef,
              { [companyId]: true },
              { merge: true }
            );
            await setDoc(companyFollowersRef, { [uid]: true }, { merge: true });
            setFollowing([...following, companyId]);
          }
          setLoading(false);
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error al manejar el seguimiento de la empresa:", error);
      setLoading(false);
    }
  };

  const isCompanyFollowed = (companyId) => following.includes(companyId);

  const formatDate = (date) => {
    if (date instanceof Date) {
      return date.toLocaleDateString();
    } else if (date && date.toDate instanceof Function) {
      return date.toDate().toLocaleDateString();
    }
    return "";
  };

  useEffect(() => {
    const fetchIcon = async () => {
      const result = await getIconForUserType();
      setIcon(result);
    };

    fetchIcon();
  });

  const getIconForUserType = async () => {
    const { uid } = auth.currentUser || {};
    if (!uid) {
      return null;
    }
    const userDocRef = doc(db, "users", uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      const userType = userData.userType;

      if (userType === "empresa") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/publishEvents">
              <AddCircleIcon sx={{ color: "#312783" }} />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Publicar
            </Typography>
          </div>
        );
      } else {
        return null;
      }
    }
  };

  const [icon, setIcon] = useState(null);

  const [busqueda, setBusqueda] = useState("");
  const [resultado, setResultados] = useState([]);

  const buscar = useCallback(() => {
    const valorBusqueda = busqueda.toLowerCase();
    const resultadosFiltrados = offers.filter((offer) =>
      offer.name.toLowerCase().includes(valorBusqueda)
    );
    setResultados(resultadosFiltrados);
  }, [busqueda, offers]);

  useEffect(() => {
    buscar();
  }, [buscar]);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalImages, setModalImages] = useState([]);

  const handleOpenImageModal = (images) => {
    setModalImages(images);
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === modalImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? modalImages.length - 1 : prevIndex - 1
    );
  };

  const [filteredOffers, setFilteredOffers] = useState([]);

  // Función para obtener el país a partir de las coordenadas
  const getCountryFromCoordinates = async (latitude, longitude) => {
    try {
      // Aquí deberías integrar una API de geocodificación como Google Maps Geocoding API
      // para obtener el país a partir de las coordenadas.
      // Puedes usar una librería como 'axios' para hacer la solicitud a la API.

      // Ejemplo de cómo usar la API de Google Maps Geocoding API:
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBG6H69JDv-9Y0O2XJXhvHgdOIJiuGzRt0`
      );
      const data = await response.json();

      // Extraer el país del resultado de la API
      const country = data.results[0].address_components.find((component) =>
        component.types.includes("country")
      ).long_name;
      console.log(country);

      return country;
    } catch (error) {
      console.error("Error al obtener el país:", error);
      return null;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const { loaded, error, coordinates } = useWatchPosition();
  // Función para filtrar las ofertas por país
  const filterOffersByCountry = async () => {
    if (loaded && coordinates) {
      const country = await getCountryFromCoordinates(
        coordinates.latitude,
        coordinates.longitude
      );

      if (country) {
        const filtered = offers.filter((offer) => offer.country === country);
        setFilteredOffers(filtered);
      } else {
        // Si no se pudo obtener el país, mostrar todas las ofertas
        setFilteredOffers(offers);
      }
    }
  };

  // Llamar a la función de filtrado cuando las coordenadas estén disponibles
  useEffect(() => {
    if (loaded && coordinates) {
      filterOffersByCountry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, coordinates]);

  const getDomainFromUrl = (url) => {
    // Si la URL no es válida, devuelve la URL original
    if (!url) {
      return url;
    }

    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "http://" + url;
    }
    const domain = new URL(url).hostname;

    // Encuentra el punto "." antes de la extensión
    const dotIndex = domain.lastIndexOf(".");

    // Si no se encuentra un punto, devuelve el dominio completo
    if (dotIndex === -1) {
      return domain;
    }

    // Extrae el dominio hasta el punto "."
    const shortenedDomain = domain.substring(0, dotIndex);

    // Si el dominio es demasiado largo, lo recorta y agrega "..."
    if (shortenedDomain.length > 15) {
      return shortenedDomain.slice(0, 15) + "...";
    }

    // Devuelve el dominio recortado
    return shortenedDomain;
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    img: {
      maxWidth: "165px",
      maxHeight: "40px",
      display: "block",
      margin: "20px auto",
    },
    slogan: {
      background:
        "linear-gradient(90deg, rgba(41,210,165,1) 31%, rgba(12,82,203,1) 62%)",
      WebkitBackgroundClip: "text",
      color: "transparent",
      fontSize: "12px",
      fontWeight: "bold",
      lineHeight: "19px",
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
      color: "black",
    },
    iconContainerL: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid white",
      justifyContent: "space-around",
    },
    backSlogan: {
      display: "block",
      width: "100vw",
      height: "19px",
      padding: "0px 0px 10px 0px",
      textAlign: "center",
    },
    listItem: {
      display: "block",
      width: "98%",
      border: "0.5px solid #ccc",
      margin: "25px auto",
      boxShadow: "0px 0px 5px 0px black",
      padding: "16px",
    },
    textField: {
      marginBottom: "30px",
    },
    contList: {
      marginTop: "-30px",
    },
    inputS: {
      display: "block",
      width: "auto",
      height: "30px",
      padding: "3px 0px 3px 20px",
      fontSize: "14px",
      border: "1px solid grey",
      borderRadius: "30px",
    },
    name: {
      color: "#312783",
      fontSize: "28px",
      fontWeight: "bold",
      marginBottom: "15px",
      marginTop: "15px",
      textDecoration: "underline",
    },
    title: {
      fontSize: "20px",
      marginBottom: "15px",
    },
    offer: {
      color: "dodgerblue",
      fontSize: "18px",
      marginBottom: "15px",
    },
    url: {
      marginBottom: "15px",
    },
    description: {
      marginBottom: "15px",
      fontWeight: "300",
    },
    cupon: {
      color: "lightseagreen",
      padding: "3px",
      borderRadius: "3px",
      backgroundColor: "lightgrey",
      fontSize: "20px",
      marginBottom: "15px",
    },
    created: {
      fontSize: "10px",
      marginBottom: "15px",
    },
    media: {
      width: "160px",
      marginTop: "20px",
      marginBottom: "20px",
    },
    contUser: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: "16px",
    },
    search: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "35px",
      border: "1.5px solid black",
      backgroundColor: "white",
    },
    imageGrid: {
      marginTop: "15px",
      marginBottom: "15px",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    noImage: {
      width: "100%",
      height: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f0f0f0",
      color: "#666",
    },
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "200px", // Adjust height as needed
    },
    mainImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    additionalImagesOverlay: {
      position: "absolute",
      bottom: "10px",
      right: "10px",
      width: "60px", // Adjust width as needed
      height: "60px", // Adjust height as needed
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      cursor: "pointer",
    },
    additionalImagesCount: {
      fontSize: "18px", // Adjust font size as needed
      fontWeight: "bold",
    },

    imageModal: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center", // Center horizontally
      alignItems: "center", // Center vertically
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
    },
    imageModalContent: {
      maxWidth: "90%", // Reduce the maximum width
      maxHeight: "90%", // Reduce the maximum height
      position: "relative",
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.5)", // Add a shadow
      display: "flex", // Add flexbox to the content
      flexDirection: "column", // Arrange items vertically
      alignItems: "center", // Center items horizontally
    },
    imageModalImage: {
      width: "100%",
      height: "auto",
      borderRadius: "10px",
      marginBottom: "20px", // Add margin below the image
    },
    imageModalNavigation: {
      position: "absolute",
      bottom: "10px", // Move navigation to the bottom
      left: "50%",
      transform: "translateX(-50%)", // Center horizontally
      display: "flex",
      justifyContent: "space-between",
      width: "auto", // Adjust width as needed
      padding: "10px", // Add padding
      backgroundColor: "rgba(255, 255, 255, 0.8)", // Add a white background with transparency
      borderRadius: "5px", // Add rounded corners
    },
    imageModalDescription: {
      textAlign: "center", // Center the text
      marginBottom: "20px", // Add margin below the description
    },
  };

  const classes = {
    appBar: {
      top: "auto",
      bottom: 0,
      backgroundColor: "white",
      color: "black",
      borderTop: "1.5px solid black",
    },
    toolbar: {
      justifyContent: "space-around",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={classes.appBar}>
        <Toolbar sx={classes.toolbar}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/home" color="black">
              <HomeIcon />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Inicio
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/members" color="black">
              <SearchIcon />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Buscar
            </Typography>
          </div>
          <div>{icon}</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/notifications" color="black">
              <NotificationIcon hasNewNotifications={hasNewNotifications} />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Notificaciones
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton href="/profile" color="black">
              <AccountCircleIcon />
            </IconButton>
            <Typography variant="caption" fontSize={9} align="center">
              Perfíl
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "10px",
          bgcolor: "#fff",
        }}
      >
        <Toolbar sx={{ flexDirection: "column", alignItems: "center" }}>
          <CardMedia
            component="img"
            image={logoSvg} // Utilizar la variable que contiene la imagen SVG
            title="Notiline"
            style={styles.media} // Especificar estilos si es necesario
          />
          <Toolbar sx={styles.iconContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/home" color="black">
                <AttachMoneyIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Ofertas
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/reference" color="black">
                <AccessTimeIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Referencias
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/events" color="black">
                <EventIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Eventos
              </Typography>
            </div>
          </Toolbar>
          <Toolbar
            sx={{
              ...styles.search,
              padding: "0px 16px",
              borderRadius: "4px",
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            }}
          >
            <input
              type="text"
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
              style={{
                ...styles.inputS,
                width: "80%",
                padding: "8px 12px",
                borderRadius: "20px",
                border: "1px solid #e0e0e0",
                fontSize: "14px",
                transition: "border-color 0.3s",
                "&:focus": {
                  outline: "none",
                  borderColor: "#3f51b5",
                },
              }}
              placeholder="Buscar por negocio..."
            />
          </Toolbar>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 29, mb: 10 }}>
        {loading ? (
          <div style={{ textAlign: "center", color: "#312783", marginTop: 20 }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {filteredOffers.length > 0 ? (
              <List>
                {filteredOffers.map((offer) => (
                  <ListItem key={offer.id} style={styles.listItem}>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: { xs: "column", md: "row" },
                      }}
                    >
                      {/* Columna izquierda para imágenes */}
                      <Box
                        sx={{
                          width: { xs: "100%", md: "30%" },
                          mr: { xs: 0, md: 2 },
                          mb: { xs: 2, md: 0 },
                        }}
                      >
                        <Box style={styles.contUser}>
                          <CardMedia
                            component="img"
                            image={offer.image}
                            title="Perfil"
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: "50%",
                              marginRight: 16,
                              border: "2.5px solid #312783",
                            }}
                          />

                          <Typography
                            onClick={() => handleClickPerfil(offer)}
                            style={styles.name}
                          >
                            {offer.name}
                          </Typography>
                        </Box>
                        {offer.imageUrls && offer.imageUrls.length > 0 ? (
                          <>
                            <Box style={styles.imageContainer}>
                              <CardMedia
                                component="img"
                                image={offer.images[0]}
                                alt={`Imagen principal de ${offer.name}`}
                                style={styles.mainImage}
                              />
                              {offer.images.length > 1 && (
                                <Box
                                  style={styles.additionalImagesOverlay}
                                  onClick={() =>
                                    handleOpenImageModal(offer.images)
                                  }
                                >
                                  <Typography
                                    style={styles.additionalImagesCount}
                                  >
                                    +{offer.images.length - 1}
                                  </Typography>
                                  <Typography>Ver más</Typography>
                                </Box>
                              )}
                            </Box>
                          </>
                        ) : (
                          <Box style={styles.noImage}>
                            <Typography>No hay imágenes disponibles</Typography>
                          </Box>
                        )}
                      </Box>

                      {/* Columna derecha para el contenido */}
                      <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                        <Typography style={styles.title}>
                          {offer.product}
                        </Typography>
                        <Typography style={styles.description}>
                          {offer.description}
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          href={`http://${offer.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{ width: "100%", mt: 1, mb: 1 }}
                        >
                          Comprar en {getDomainFromUrl(offer.url)}
                        </Button>
                        <Typography style={styles.offer}>
                          Descuento: {offer.offer}%
                        </Typography>
                        <CopyToClipboardComponent textToCopy={offer.cupon} />
                        <Typography style={styles.created}>
                          Fecha de publicación: {formatDate(offer.createdAt)}
                        </Typography>
                        <Box>
                          <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => {
                              handleFollow(offer.uid);
                            }}
                          >
                            {isCompanyFollowed(offer.uid)
                              ? "Dejar de seguir"
                              : "Seguir"}
                          </Button>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              mt: 1,
                            }}
                          >
                            <IconButton
                              onClick={() => handleMeSirve(offer.id)}
                              color={
                                userLikes[offer.id] ? "primary" : "default"
                              }
                              aria-label="me sirve"
                              disabled={!userAuthenticated}
                            >
                              <ThumbUpAltIcon />
                            </IconButton>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ ml: 1 }}
                            >
                              {offer.helpedCount || 0}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            ) : resultado.length > 0 ? (
              <List>
                {resultado.map((offer) => (
                  <ListItem key={offer.id} style={styles.listItem}>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: { xs: "column", md: "row" },
                      }}
                    >
                      {/* Columna izquierda para imágenes */}
                      <Box
                        sx={{
                          width: { xs: "100%", md: "30%" },
                          mr: { xs: 0, md: 2 },
                          mb: { xs: 2, md: 0 },
                        }}
                      >
                        <Box style={styles.contUser}>
                          <CardMedia
                            component="img"
                            image={offer.image}
                            title="Perfil"
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: "50%",
                              marginRight: 16,
                              border: "2.5px solid #312783",
                            }}
                          />

                          <Typography
                            onClick={() => handleClickPerfil(offer)}
                            style={styles.name}
                          >
                            {offer.name}
                          </Typography>
                        </Box>
                        {offer.imageUrls && offer.imageUrls.length > 0 ? (
                          <>
                            <Box style={styles.imageContainer}>
                              <CardMedia
                                component="img"
                                image={offer.images[0]}
                                alt={`Imagen principal de ${offer.name}`}
                                style={styles.mainImage}
                              />
                              {offer.images.length > 1 && (
                                <Box
                                  style={styles.additionalImagesOverlay}
                                  onClick={() =>
                                    handleOpenImageModal(offer.images)
                                  }
                                >
                                  <Typography
                                    style={styles.additionalImagesCount}
                                  >
                                    +{offer.images.length - 1}
                                  </Typography>
                                  <Typography>Ver más</Typography>
                                </Box>
                              )}
                            </Box>
                          </>
                        ) : (
                          <Box style={styles.noImage}>
                            <Typography>No hay imágenes disponibles</Typography>
                          </Box>
                        )}
                      </Box>

                      {/* Columna derecha para el contenido */}
                      <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                        <Typography style={styles.title}>
                          {offer.product}
                        </Typography>
                        <Typography style={styles.description}>
                          {offer.description}
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          href={`http://${offer.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{ width: "100%", mt: 1, mb: 1 }}
                        >
                          Comprar en {getDomainFromUrl(offer.url)}
                        </Button>
                        <Typography style={styles.offer}>
                          Descuento: {offer.offer}%
                        </Typography>
                        <CopyToClipboardComponent textToCopy={offer.cupon} />
                        <Typography style={styles.created}>
                          Fecha de publicación: {formatDate(offer.createdAt)}
                        </Typography>
                        <Box
                          styles={{
                            margin: "10px",
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => {
                              handleFollow(offer.uid);
                            }}
                          >
                            {isCompanyFollowed(offer.uid)
                              ? "Dejar de seguir"
                              : "Seguir"}
                          </Button>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              mt: 1,
                            }}
                          >
                            <IconButton
                              onClick={() => handleMeSirve(offer.id)}
                              color={
                                userLikes[offer.id] ? "primary" : "default"
                              }
                              aria-label="me sirve"
                              disabled={!userAuthenticated}
                            >
                              <ThumbUpAltIcon />
                            </IconButton>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ ml: 1 }}
                            >
                              {offer.helpedCount || 0}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            ) : (
              <List>
                {offers.map((offerData) => (
                  <ListItem key={offerData.id} style={styles.listItem}>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: { xs: "column", md: "row" },
                      }}
                    >
                      {/* Columna izquierda para imágenes */}
                      <Box
                        sx={{
                          width: { xs: "100%", md: "30%" },
                          mr: { xs: 0, md: 2 },
                          mb: { xs: 2, md: 0 },
                        }}
                      >
                        <Box style={styles.contUser}>
                          <CardMedia
                            component="img"
                            image={offerData.image}
                            title="Perfil"
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: "50%",
                              marginRight: 16,
                              border: "2.5px solid #312783",
                            }}
                          />
                          <Link href={`/perfil/${offerData.uid}`}>
                            <Typography style={styles.name}>
                              {offerData.name}
                            </Typography>
                          </Link>
                        </Box>
                        {offerData.imageUrls &&
                        offerData.imageUrls.length > 0 ? (
                          <>
                            <Box style={styles.imageContainer}>
                              <CardMedia
                                component="img"
                                image={offerData.images[0]}
                                alt={`Imagen principal de ${offerData.name}`}
                                style={styles.mainImage}
                              />
                              {offerData.images.length > 1 && (
                                <Box
                                  style={styles.additionalImagesOverlay}
                                  onClick={() =>
                                    handleOpenImageModal(offerData.images)
                                  }
                                >
                                  <Typography
                                    style={styles.additionalImagesCount}
                                  >
                                    +{offerData.images.length - 1}
                                  </Typography>
                                  <Typography>Ver más</Typography>
                                </Box>
                              )}
                            </Box>
                          </>
                        ) : (
                          <Box style={styles.noImage}>
                            <Typography>No hay imágenes disponibles</Typography>
                          </Box>
                        )}
                      </Box>

                      {/* Columna derecha para el contenido */}
                      <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                        <Typography style={styles.title}>
                          {offerData.product}
                        </Typography>
                        <Typography style={styles.description}>
                          {offerData.description}
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          href={`http://${offerData.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{ width: "100%", mt: 1, mb: 1 }}
                        >
                          Comprar en {getDomainFromUrl(offerData.url)}
                        </Button>
                        <Typography style={styles.offer}>
                          Descuento: {offerData.offer}%
                        </Typography>
                        <CopyToClipboardComponent
                          textToCopy={offerData.cupon}
                        />
                        <Typography style={styles.created}>
                          Fecha de publicación:{" "}
                          {formatDate(offerData.createdAt)}
                        </Typography>
                        <Box
                          styles={{
                            margin: "10px",
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => {
                              handleFollow(offerData.uid);
                            }}
                            disabled={loading}
                          >
                            {isCompanyFollowed(offerData.uid)
                              ? "Dejar de seguir"
                              : "Seguir"}
                          </Button>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              mt: 1,
                            }}
                          >
                            <IconButton
                              onClick={() => handleMeSirve(offerData.id)}
                              color={
                                userLikes[offerData.id] ? "primary" : "default"
                              }
                              aria-label="me sirve"
                              disabled={!userAuthenticated}
                            >
                              <ThumbUpAltIcon />
                            </IconButton>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ ml: 1 }}
                            >
                              {offerData.helpedCount || 0}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            )}
          </>
        )}
        <Dialog
          open={openImageModal}
          onClose={handleCloseImageModal}
          aria-labelledby="image-modal-title"
          aria-describedby="image-modal-description"
          fullScreen // Make the modal full screen
        >
          <DialogTitle id="image-modal-title">
            Imágenes del Alojamiento
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="image-modal-description">
              {openImageModal && (
                <Box style={styles.imageModalContent}>
                  {modalImages && modalImages.length > 0 ? (
                    <CardMedia
                      component="img"
                      image={modalImages[currentImageIndex]}
                      alt={`Imagen ${currentImageIndex + 1} del alojamiento`}
                      style={styles.imageModalImage}
                    />
                  ) : (
                    <Typography>No hay imágenes disponibles</Typography>
                  )}
                  <Box style={styles.imageModalNavigation}>
                    <IconButton onClick={handlePreviousImage}>
                      <ArrowBackIosIcon />
                    </IconButton>
                    <IconButton onClick={handleNextImage}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImageModal}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Container>
  );
};

export default Home;
